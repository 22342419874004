import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Rating and reviews</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            General
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">2697</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Jakub Nowak</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">26.08.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Mój Boże, najlepsze kasyno, jakie kiedykolwiek widziałem! Zacząłem
            grać i od razu dostałem bonus. Już dawno nie miałem tyle szczęścia,
            dzięki za tak fajny projekt!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kasyno</p>
            <p className="review__description">
              Jakub Nowak, dzieki za informacje zwrotna! To dla nas bardzo
              wazne, aby nasi klienci cieszyli sie naszym projektem. Nasz zespót
              robi wszystko, abys czerpat maksymalna przyjemnosé z korzystania z
              naszego produktu!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Kacper Kowalski</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">25.08.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Chłopaki po raz kolejny stworzyli topowy projekt, który nie ma
            analogii. Najlepsi wśród konkurentów. Fajne automaty i szalone
            bonusy. Dla zabawy wpłaciłem 1500 zł i wypłaciłem na kartę bankową
            9300 zł! Fajny projekt, z pewnością wysadzi rynek!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kasyno</p>
            <p className="review__description">
              Kacper Kowalski, dziekuje za pozytywne opinie. Nieustannie
              pracujemy nad udoskonaleniem naszego projektu, bardzo sie
              cieszymy, ¿e Ci sie podoba!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Antoni Wisniewski</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">24.08.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            Zwykle nie wierzę we wszystkie te kasyna! Ale ta aplikacja to po
            prostu coś! Wrzuciłem do gry 300 zł i w 15 minut zebrałem 3000 zł.
            Będę nadal grać i zarabiać pieniądze!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">Kasyno</p>
            <p className="review__description">
              Antoni Wisniewski, dziekuje za mita recenzje! Graj i ciesz sie!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Filip Zielinski</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGrey}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">23.08.2024</p>
                </div>
                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
              </div>
              <p className="item__text">
                Nie spodziewałem się, że chłopaki robią tak fajny projekt! Mój
                szacunek! Najbardziej hojne kasyno w moim życiu! jestem mile
                zaskoczony)
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kasyno</p>
                <p className="review__description">
                  Filip Zielinski, dziekuje za twoja opinie. Nasz projekt
                  powstat wytacznie po to, by dawac ludziom emocje. Postaramy
                  sie jak najlepiej, abys byt szczesliwy! Powodzenia!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                Kasyno
!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kasyno
</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее Kasyno
 в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kasyno
</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Hide reviews" : "All reviews"}
      </button>
    </>
  );
};
