import { useEffect } from "react";

export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let sub_id_4 = param.get("sub_id_4");
  let id = param.get("id");
  let pixel = param.get("pixel");
  let fbclid = param.get("fbclid");
  let access_token = param.get("access_token");

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!pixel) {
    pixel = localStorage.getItem("pixel");
  }

  if (!fbclid) {
    fbclid = localStorage.getItem("fbclid");
  }

  if (!access_token) {
    access_token = localStorage.getItem("access_token");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!sub_id_4) {
    sub_id_4 = localStorage.getItem("sub_id_4");
  }
  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }
  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  useEffect(() => {
    // localStorage.removeItem("isClickedInstall");
    // const sendPixel = async () => {
    //   if (localStorage.getItem("pixelUsed")) {
    //     return;
    //   } else {
    //     await fetch(
    //       `https://naomc.top/api/postback/send?pixel=${pixel}&access_token=${access_token}&fbc=${fbclid}&status=lead`
    //     );
    //     localStorage.setItem("pixelUsed", true);
    //   }
    // };

    // sendPixel();

    setTimeout(() => {
      if (id === "1470") {
        window.location.href = `https://arumo.top/ZV7M25vt?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1471") {
        window.location.href = `https://arumo.top/fBqzFZF1?ad_campaign_id=${ad_campaign_id}`;
      } else if (id === "1472") {
        window.location.href = `https://arumo.top/rVDSmx6x?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1473") {
        window.location.href = `https://arumo.top/cRvn59mq?ad_campaign_id=${ad_campaign_id}`;
      } else {
        window.location.href = `https://arumo.top/ZV7M25vt?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      }
    }, 800);
  }, []);

  return <div></div>;
};
